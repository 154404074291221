// routes
import { PATH_PROFILE, PATH_DASHBOARDS, PATH_LOAN, PATH_NOTIFICATION } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} sx={{ width: 25, height: 25 }} />;

const navConfig = [
  // HOME
  // ----------------------------------------------------------------------
  {
    title: 'dashboard',
    path: PATH_DASHBOARDS.root,
    icon: subMenuIcon('bxs:home'),
  },

  // LOAN
  // ----------------------------------------------------------------------
  {
    title: 'my loans',
    path: PATH_LOAN.root,
    icon: subMenuIcon('fa:bank'),
  },

  // NOTIFICATIONS
  // ----------------------------------------------------------------------
  {
    title: 'notification',
    path: PATH_NOTIFICATION.root,
    icon: subMenuIcon('ph:bell-fill'),
  },

  // ACCOUNT
  // ----------------------------------------------------------------------
  {
    title: 'profile',
    path: PATH_PROFILE.root,
    icon: subMenuIcon('mdi:account-tie'),
  },
];

export default navConfig;
