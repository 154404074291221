import React from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// GA
import ReactGA from 'react-ga4';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER } from '../../config';

//
import DashboardHeader from './header';

import ComingSoon from '../../pages/ComingSoon';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { useSelector } from '../../redux/store';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'visible',
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 5,
  paddingBottom: theme.spacing(1),
  paddingLeft: 10,
  paddingRight: 10,
  height: '100%',
  minHeight: '100vh',
}));
// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const isDesktop = useResponsive('up', 'lg');

  const { hideMenu } = useSelector((x) => x.pageTitle);

  ReactGA.initialize('G-G7FPD73NGE');

  if (isDesktop) return <ComingSoon />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DashboardHeader />

      <MainStyle>
        <img
          src={require('../../assets/mobile-bg.png')}
          style={{ position: 'fixed', height: '100%', width: '100%', top: 0, right: 0, bottom: 0, left: 0, zIndex: -1 }}
          alt=""
        />
        <Outlet />
      </MainStyle>

      {!hideMenu && <NavbarHorizontal />}
    </Box>
  );
};

export default DashboardLayout;
