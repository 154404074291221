// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const km = {
  dashboard: 'ផ្ទាំងគ្រប់គ្រង',
  my_loans: 'ប្រាក់កម្ចីរបស់ខ្ញុំ',
  notification: 'សេចក្តីជូនដំណឹង',
  wait_while_profile_being_verified: 'Kindly wait while your profile is being verified.',
  account: 'សូមរង់ចាំខណៈពេលដែលប្រវត្តិរូបរបស់អ្នកកំពុងត្រូវបានផ្ទៀងផ្ទាត់។',
  chat: 'ជជែក',
  online_bank_account: 'គណនីធនាគារតាមអ៊ីនធឺណិត',
  x_is_required: '{{x}} ត្រូវបានទាមទារ',
  bank: 'ធនាគារ',
  account_number: 'លេខ​គណនី',
  x_must_be_y_to_z_digits_in_length: '{{x}} ត្រូវតែជា {{y}}-{{z}} ខ្ទង់នៅក្នុងប្រវែង',
  payment: 'ការទូទាត់',
  total_payment: 'ការទូទាត់សរុប',
  wrong_otp: 'OTP ខុស',
  refer_a_friend: 'យោងមិត្តម្នាក់',
  apply_loan_details: 'អនុវត្តព័ត៌មានលម្អិតអំពីប្រាក់កម្ចី',
  my_listing: 'ការចុះបញ្ជីរបស់ខ្ញុំ',
  edit_profile: 'កែសម្រួលប្រវត្តិរូប',
  extra_documents: 'ឯកសារបន្ថែម',
  employer_name: 'ឈ្មោះ​និយោជក',
  employer_contact_number: 'លេខទំនាក់ទំនងនិយោជក',
  upload_payslip_for_last_month: 'បង្ហោះ​បញ្ជី​ប្រាក់​ខែ​សម្រាប់​ខែ​មុន។',
  upload_bank_statement_salary_last_month: 'បង្ហោះរបាយការណ៍ប្រាក់បៀវត្សរ៍របស់ធនាគារសម្រាប់ខែមុន។',
  upload_utility_bill_last_month: 'បង្ហោះវិក្កយបត្រប្រើប្រាស់សម្រាប់ខែមុន។',
  upload_active_mortgage_statement: 'បង្ហោះរបាយការណ៍បញ្ចាំសកម្ម',
  dont_worry_your_data_is_kept_protected:
    'កុំបារម្ភ ទិន្នន័យរបស់អ្នកត្រូវបានរក្សាទុកតាមច្បាប់ និងត្រូវបានការពារដោយពួកយើង!',
  nric_front: 'រណសិរ្ស ID Card',
  upload_nric_front: 'ផ្ទុកឡើង ID Card Front',
  nric_back: 'ID Card ត្រឡប់មកវិញ',
  upload_nric_back: 'ផ្ទុក ID Card ត្រឡប់មកវិញ',
  nric_number: 'លេខ ID Card',
  complete_profile_to_submit_loan_application: 'សូមបំពេញប្រវត្តិរូបរបស់អ្នក ដើម្បីដាក់ពាក្យស្នើសុំកម្ចី។',
  name_as_per_ic: 'ឈ្មោះតាម ID Card',
  view_example: 'View Example >',
  upload_video_selfie_together_with_nric_x_appname:
    'Upload Video Selfie together with ID Card, Hold the ID Card and say your name to borrow with {{x}}',
  emergency_contact_1: 'Emergency Contact 1',
  emergency_contact_2: 'Emergency Contact 2',
  relationship: 'Relationship',
  name: 'ឈ្មោះ',
  done: 'រួចរាល់',
  data_is_being_prep: 'ទិន្នន័យកំពុងត្រូវបានរៀបចំ',
  loan_application_failed: 'ពាក្យស្នើសុំប្រាក់កម្ចីបរាជ័យ!',
  title_: 'ចំណងជើង: ',
  message_: 'សារ: ',
  contact_support: 'ទាក់ទងផ្នែកជំនួយ',
  ok: 'យល់ព្រម',
  receive_method: 'វិធីសាស្រ្តទទួល',
  select_bank: 'ជ្រើសរើសធនាគារ',
  key_in_account_number: 'បញ្ចូលលេខគណនី',
  congratulations: 'អបអរសាទរ!',
  your_loan_has_been_approved: 'ប្រាក់កម្ចីរបស់អ្នកត្រូវបាន\nអនុម័ត!',
  payment_amount_xcurrencyx: 'ចំនួនទឹកប្រាក់ទូទាត់ ({{x}})',
  interest: 'ការប្រាក់',
  underwriting_fee: 'ថ្លៃធានាទិញ',
  gateway_fee: 'Gateway Fee',
  service_fee: 'Service Fee',
  deposit: 'Deposit',
  total_amount_received: 'ចំនួនសរុបដែលបានទទួល',
  apply_now: 'ដាក់ពាក្យ​ឥឡូវនេះ',
  application_submitted_wait_pic_to_serve_u:
    'ពាក្យស្នើសុំរបស់អ្នកត្រូវបានបញ្ជូនដោយជោគជ័យ។ សូមរង់ចាំអ្នកទទួលខុសត្រូវរបស់យើង មកបម្រើអ្នក។',
  mobile_number: 'Mobile Number',
  _due_: 'ដល់កំណត់: ',
  pay_now: 'បង់ឥឡូវនេះ',
  recent_transactions: 'ប្រតិបត្តិការថ្មីៗ',
  no_loan_yet: 'មិនទាន់មានកម្ចីនៅឡើយ',
  enter_any_payment_amount: 'បញ្ចូលចំនួនទឹកប្រាក់ទូទាត់ណាមួយ។',
  select_payment_method: 'ជ្រើសរើសវិធីបង់ប្រាក់',
  invite_your_friend_to_x_app: 'អញ្ជើញមិត្តរបស់អ្នកទៅកម្មវិធី {{x}}!',
  about_x: 'អំពី {{x}}',
  upload_receipt_x_will_verify_payment:
    'សូមបង្ហោះរូបភាពនៃ ATM / បង្កាន់ដៃដាក់ប្រាក់ នោះ {{x}} នឹងផ្ទៀងផ្ទាត់ការទូទាត់របស់អ្នកក្នុងរយៈពេល 3 ថ្ងៃធ្វើការ។',
  pay_to_either_one_of_x_accounts:
    'ប្រើម៉ាស៊ីន ATM ឬប្រាក់បញ្ញើជាសាច់ប្រាក់ ដើម្បីទូទាត់ទៅគណនីមួយក្នុងចំណោមគណនីរបស់ {{x}} ខាងក្រោម៖',
  youre_one_step_away_x_click_next_to_proceed:
    'ឥឡូវនេះ អ្នកនៅឆ្ងាយពីការរីករាយជាមួយអត្ថប្រយោជន៍នៃ {{x}} មួយជំហានទៀតហើយ។ ចុច Next ដើម្បីបន្ត។',
  welcome_to_x: 'សូមស្វាគមន៍មកកាន់ {{x}}',
  share_now: 'ចែករំលែកឥឡូវនេះ',
  copy: 'ចម្លង',
  your_referral_history: 'ប្រវត្តិយោងរបស់អ្នក។',
  share_your_referral_code: 'ចែករំលែកលេខកូដយោងរបស់អ្នក។',
  how_referral_works: 'របៀបដែលការបញ្ជូនបន្តដំណើរការ',
  copied: 'ចម្លង',
  how_referral_works_x_y_commission:
    'រូបមន្តកម្រៃជើងសាររបស់យើងអនុញ្ញាតឱ្យការបញ្ជូនបន្តទទួលបាន {{x}} និង {{y}} ពីប្រាក់កម្ចីដំបូង និងប្រាក់កម្ចីបន្តបន្ទាប់នៃអ្នកអញ្ជើញរបស់ពួកគេរៀងៗខ្លួន។ កំរៃជើងសារទាំងនេះនឹងត្រូវបានបញ្ចូលទៅក្នុងការណែនាំ នៅពេលអ្នកអញ្ជើញរបស់ពួកគេបានបញ្ចប់ការសងប្រាក់ពេញលេញ។',
  customer_support: 'ជំនួយអតិថិជន',
  date: 'កាលបរិច្ឆេទ',
  amount: 'ចំនួនទឹកប្រាក់',
  status: 'ស្ថានភាព',
  enter_payment_amount: 'បញ្ចូលចំនួនទឹកប្រាក់ទូទាត់',
  entered_amount_more_than_you_owe: 'ចំនួនដែលបានបញ្ចូលគឺច្រើនជាងអ្វីដែលអ្នកជំពាក់។',
  oops_we_are_sorry: 'អូ ពួកយើងសុំទោស',
  more_time_needed_will_inform_once_completed: 'ត្រូវការពេលវេលាបន្ថែមទៀត យើងនឹងជូនដំណឹងដល់អ្នកនៅពេលវាត្រូវបានបញ្ចប់។',
  contact_us: 'ទាក់ទង​មក​ពួក​យើង',
  proceed_to_online_banking_to_pay_loan: 'បន្តទៅធនាគារអនឡាញដើម្បីបង់ប្រាក់កម្ចី',
  proceed: 'បន្ត',
  thank_you_for_payment: 'សូម​អរគុណ​ចំពោះ​ការ​បង់ប្រាក់​របស់​អ្នក​!',
  please_register_an_account: 'សូមចុះឈ្មោះគណនីមួយ។',
  referral_code_optional: 'លេខកូដយោង (ស្រេចចិត្ត)',
  register: 'ចុះឈ្មោះ',
  downline_name: 'Downline Name',
  no_: 'ទេ',
  total_commission: 'Total Commission',
  total_withdrawal: 'Total Withdrawal',
  balance: 'តុល្យភាព',
  total_register_: 'Total Register: ',
  total_loans_: 'Total Loans: ',
  upload_your_receipt: 'Upload your receipt',
  email_address: 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
  password: 'ពាក្យសម្ងាត់',
  forget_password: 'ភ្លេច​លេខសំងាត់​',
  sign_in: 'ចូល',
  upload_photo: 'Upload Photo',
  title: 'ចំណងជើង',
  edit: 'កែសម្រួល',
  delete: 'លុប',
  view: 'មើល',
  no: 'ទេ',
  yes: 'បាទ',
  phone_number: 'លេខទូរសព្ទ',
  save: 'រក្សាទុក',
  forgot_password: 'ភ្លេច​លេខសំងាត់​',
  submit: 'ដាក់ស្នើ',
  please_verify_account_first: 'សូមផ្ទៀងផ្ទាត់គណនីជាមុនសិន',
  are_you_ready_to_nbe_a_part_of_the_family: 'តើអ្នកត្រៀមខ្លួនហើយឬនៅដើម្បី\nក្លាយជាផ្នែកមួយនៃគ្រួសារ។',
  full_name: 'ឈ្មោះ​ពេញ',
  verify_account: 'ផ្ទៀងផ្ទាត់គណនី',
  skip: 'រំលង',
  next: 'បន្ទាប់',
  let_s_get_started: 'តោះ​ចាប់ផ្តើម',
  please_enter_otp: 'សូមបញ្ចូល OTP ដែលផ្ញើទៅកាន់លេខរបស់អ្នក។',
  new_password: 'ពាក្យសម្ងាត់​ថ្មី',
  please_enter_your_nnew_password_to_reset_your_password:
    'សូមបញ្ចូល\nពាក្យសម្ងាត់ថ្មីរបស់អ្នក ដើម្បីកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញ',
  confirm_password: 'បញ្ជាក់ពាក្យសម្ងាត់',
  reset_password: 'កំណត់ពាក្យសម្ងាត់ឡើងវិញ',
  welcome_back_yo_ve_nbeen_missed: 'សូមស្វាគមន៍ការត្រលប់មកវិញដែលអ្នកបាន\nខកខាន!',
  let_s_sign_you_in: 'តោះចុះឈ្មោះចូល។',
  choose_your_language: 'Select Your language to begin!',
  login_now: 'ចូលឥឡូវនេះ។',
  sign_up: 'ចុះ​ឈ្មោះ',
  log_in: 'ចូល',
  empty_field_found: 'វាលទទេបានរកឃើញ',
  invalid_x: 'មិនត្រឹមត្រូវ {{x}}',
  invalid_nric_please_enter_x_format: 'ទម្រង់ ID Card មិនត្រឹមត្រូវ។ សូមបញ្ចូល {{x}}',
  something_went_wrong_x: 'មានអ្វីមួយខុសប្រក្រតី៖ {{x}}',
  something_went_wrong_pls_contact_support: 'មាន​អ្វីមួយ​មិន​ប្រក្រតី។ សូមទាក់ទងផ្នែកជំនួយ។',
  enter_valid_mobile_no: 'បញ្ចូលលេខទូរសព្ទដែលមានសុពលភាព',
  record_already_exists: 'កំណត់ត្រាមានរួចហើយ',
  x_already_exists: 'មាន {{x}} រួចហើយ!',
  permission_turned_off_can_be_enabled_in_app_settings:
    'វាហាក់ដូចជាអ្នកបានបិទការអនុញ្ញាតដែលត្រូវការសម្រាប់មុខងារនេះ។ វាអាចត្រូវបានបើកនៅក្រោមការកំណត់កម្មវិធី!',
  go_to_settings: 'ចូលទៅកាន់ការកំណត់',
  cancel: 'បោះបង់',
  payment_info: 'ព​ត៌​មាន​ទូទាត់ប្រាក់',
  no_amount_and_id_found: 'រកមិនឃើញចំនួន និងលេខសម្គាល់ទេ។',
  name_of_correspondence: 'ឈ្មោះនៃការឆ្លើយឆ្លង',
  number_of_correspondence: 'ចំនួននៃការឆ្លើយឆ្លង',
  emergency_contact_1_2_x_shouldnt_be_the_same: 'Emergency Contact 1 and 2 {{x}} should not be the same',
  referral_history: 'Referral History',
  withdrawal_history: 'Withdrawal History',
  loan_packages: 'Loan Packages',
  ensure_settle_payment_b4_due_date_to_avoid_penalty:
    'ត្រូវប្រាកដថាអ្នកទូទាត់ការទូទាត់របស់អ្នកមុនកាលបរិច្ឆេទកំណត់ ដើម្បីជៀសវាងការពិន័យការទូទាត់។',
  period: 'រយៈពេល',
  identity_nverification: 'អត្តសញ្ញាណ\n ការផ្ទៀងផ្ទាត់',
  threemin_nreview: '3-minute\n Review',
  threemin_nreceival: '3-minute\n Receival',
  nric_name: 'ឈ្មោះ IC',
  _days: 'ថ្ងៃ ',
  details: 'ព័ត៌មានលម្អិត',
  announcements: 'សេចក្តីប្រកាស',
  no_internet_connection: 'គ្មានការភ្ជាប់អ៊ីនធឺណិត',
  retry: 'ព្យាយាមម្តងទៀត',
  code_sent: 'លេខ​កូដ​ដែល​បាន​ផ្ញើ',
  otp_verification_failed: 'ការផ្ទៀងផ្ទាត់ OTP បានបរាជ័យ',
  invalid_credential: 'អត្តសញ្ញាណប័ណ្ណមិនត្រឹមត្រូវ',
  firebase_quota_exceeded: 'លើសកូតា',
  wrong_otp_code: 'កូដ OTP ខុស',
  too_many_request_detected: 'Too many request detected, please try again later',
  account_submitted: 'ព័ត៌មានគណនីត្រូវបានបញ្ជូន',
  loan_application_submitted: 'ពាក្យស្នើសុំប្រាក់កម្ចីត្រូវបានបញ្ជូន',
  please_wait_our_admin_to_contact_you_soon: 'សូមរង់ចាំ Admin របស់យើងទាក់ទងទៅអ្នកឆាប់ៗនេះ។',
  whats_app_no: 'លេខ WhatsApp',
  invite_message_x_referral_code_y: 'ហេ ខ្ញុំបានរកឃើញកម្មវិធីកម្ចីការប្រាក់សូន្យ។ {{x}} លេខកូដយោងរបស់ខ្ញុំគឺ {{y}}',
  nric_format_name: 'លេខ ID Card ដោយគ្មានសញ្ញា',
  nrc_min_length_x: 'ប្រវែងអប្បបរមាគឺ {{x}}',

  // <!--  Premloan new strings 04/01/2023  -->
  login_note: 'ចូលដើម្បីស្វែងរកលក្ខណៈពិសេសដ៏អស្ចារ្យនៃកម្មវិធីរបស់យើង។',
  or_register_now: '<u>ឬចុះឈ្មោះឥឡូវនេះ</u>',
  register_your_phone_number: 'ចុះឈ្មោះ \n លេខទូរស័ព្ទរបស់អ្នក។',
  register_note: 'លេខរបស់អ្នកមានសុវត្ថិភាពជាមួយយើង។ យើងនឹងមិនចែករំលែកព័ត៌មានលម្អិតរបស់អ្នកជាមួយនរណាម្នាក់ឡើយ។',
  same_number: 'លេខ WhatsApp របស់ខ្ញុំគឺដូចគ្នានឹងលេខទូរស័ព្ទរបស់ខ្ញុំដែរ។',
  already_have_account: 'មានគណនីមួយ? ចូលទីនេះ',
  welcome: 'សូមស្វាគមន៍',
  to_x: 'ទៅ {{x}}',
  or: 'ឬ',
  enter_loan_amount: 'បញ្ចូលចំនួនប្រាក់កម្ចី',
  minimum_amount: 'ចំនួនទឹកប្រាក់អប្បបរមា:',
  maximum_amount: 'ចំនួនទឹកប្រាក់អតិបរមា:',
  apply_loan_note:
    'រក្សាគណនីរបស់អ្នកឱ្យសកម្ម កសាងទំនុកចិត្ត និងសងប្រាក់កម្ចីរបស់អ្នកទាន់ពេល ដើម្បីបង្កើនដែនកំណត់របស់អ្នក។',
  apply_online: 'ចំណេញពេលវេលា និងថវិកា ដាក់កម្មវិធីអនឡាញឥឡូវនេះ!',
  fast_and_easy_application: 'កម្មវិធីរហ័ស ​​និងងាយស្រួល &amp; ការ​ផ្ទេរ​មូលនិធិ',
  data_is_safe: 'ទិន្នន័យផ្ទាល់ខ្លួនរបស់អ្នកមានសុវត្ថិភាពជាមួយយើង',
  no_notification: 'អ្នកមិនមានការជូនដំណឹងថ្មីទេ!',
  refer_your_friends: 'យោងមិត្តរបស់អ្នក!',
  otp_sent: 'យើងបានផ្ញើ OTP ទៅកាន់លេខទូរស័ព្ទរបស់អ្នក។',
  loan_amount_between_x_and_y: 'ចំនួនប្រាក់កម្ចីត្រូវតែស្ថិតនៅចន្លោះ {{x}} និង {{y}}',
  approved: 'បានអនុម័ត',
  pending_approval: 'រង់ចាំការអនុម័ត',
  select_country_region: 'ជ្រើសរើសប្រទេស / តំបន់',
  request_contact_list_permission_reason:
    'យើងតម្រូវឱ្យចូលប្រើបញ្ជីទំនាក់ទំនងរបស់អ្នក ដើម្បីជួយស្វែងរក និងការពារការក្លែងបន្លំ។ តាមរយៈការប្រៀបធៀបទំនាក់ទំនងរបស់អ្នកជាមួយនឹងមូលដ្ឋានទិន្នន័យរបស់យើង យើងអាចកំណត់អត្តសញ្ញាណសកម្មភាពគួរឱ្យសង្ស័យណាមួយ និងជួយរក្សាគណនី និងព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកឱ្យមានសុវត្ថិភាព។',
  permission_request: 'ការស្នើសុំការអនុញ្ញាត',
  app_wont_work_without_permission: 'កម្មវិធីនឹងមិនដំណើរការដោយគ្មានការអនុញ្ញាតទេ: ',
  go_to_app_settings_allow_permission_manually: 'សូមចូលទៅកាន់ការកំណត់កម្មវិធី ហើយអនុញ្ញាតឱ្យពួកវាដោយដៃ',
  allow: 'អនុញ្ញាត',
  take_photo_using_camera: 'ថតរូបដោយប្រើកាមេរ៉ា',
  select_photo_from_gallery: 'ជ្រើសរើសរូបថតពីវិចិត្រសាល',
  select_pdf_file: 'ជ្រើសរើសឯកសារ PDF',
  failed_to_attach_photo: 'បរាជ័យក្នុងការភ្ជាប់រូបថត',
  signup_agree_privacy_policy: 'តាមរយៈការចុះឈ្មោះ អ្នកយល់ព្រមនឹងគោលការណ៍ឯកជនភាពរបស់យើង។',
  login_agree_privacy_policy: 'តាមរយៈការចូល អ្នកយល់ព្រមនឹងគោលការណ៍ឯកជនភាពរបស់យើង។',
  request_upload_photo_permission_reason:
    'យើងទាមទារការអនុញ្ញាតពីអ្នកក្នុងការបង្ហោះរូបថតពីឧបករណ៍របស់អ្នកទៅកាន់ម៉ាស៊ីនមេរបស់យើងដោយសុវត្ថិភាពសម្រាប់គោលបំណង KYC ។ យើងនឹងមិនចែករំលែករូបថតរបស់អ្នកជាមួយភាគីទីបីណាមួយដោយគ្មានការយល់ព្រមច្បាស់លាស់របស់អ្នកឡើយ។',
  upload_failed_please_try_again: 'ការបង្ហោះបានបរាជ័យ។ សូម​ព្យាយាម​ម្តង​ទៀត',
  loan_payment_failed: 'ការទូទាត់ប្រាក់កម្ចីបានបរាជ័យ',
  upload_selfie_with_id: `បង្ហោះ Selfie រួមគ្នាជាមួយអត្តសញ្ញាណប័ណ្ណ`,
};

export default km;
